<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="审核"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <div style="max-height: 450px; overflow-y: scroll">
      <a-form :label-col="{ span: 5 }">
        <a-form-item label="申请退款原因">
          {{auditRecordVo.reason }}
        </a-form-item>
        <a-form-item label="审核结果">
          <a-radio-group v-model:value="auditVo.auditState">
            <a-radio :value="-1">驳回退款</a-radio>
            <a-radio :value="1">同意退款</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="审核说明">
          <a-textarea
            :row="2"
            v-model:value="auditVo.remark"
            style="width: 100%"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { auditRefund } from "@/api/info/refundRecordApi";
import useRefundRecord from "../../../../hooks/Info/useRefundRecord";
import { detail } from "../../../../api/info/refundRecordApi";

export default {
  name: "Audit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { auditVo, auditRecordVo } = useRefundRecord();

    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = async () => {
      let action = await showConfirm("是否提交审核?");
      if (action) {
        disabled.value = true;
        await showLoading();
        try {
          await auditRefund(props.id, auditVo);
          await closeLoading();
          await showTipMessage("提交成功");
          refresh = true;
          visible.value = false;
        } catch (e) {
          await closeLoading();
          await showErr(e);
          disabled.value = false;
        }
      }
    };
    onMounted(async () => {
      try {
        let { data } = await detail(props.id);
        auditRecordVo.reason = data.reason;
      } catch (e) {
        await showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      auditVo,
      auditRecordVo,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
